import {
  Pagination,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@electro/shared-ui-components'
import { ComponentType, ReactNode, useMemo } from 'react'
import { v1 as uuid } from 'uuid'
import {
  DataGridProvider,
  DataGridProviderProps,
  Ordering,
  useDataGrid,
} from 'libs/shared-ui-components/src/lib/DataGrid/hooks'
import { DataGridHeader, DataGridRow } from 'libs/shared-ui-components/src/lib/DataGrid/components'

export interface DataGridColumnDef {
  field: string
  name: string
  orderField?: string
  valueGetter?: (value, row: DataGridRowDef) => number | string
  renderCell?: (value, row: DataGridRowDef) => ReactNode | ReactNode[]
}

export interface DataGridRowDef {
  [key: string]: any
}

export interface DataGridChangeParams<T = any> {
  first: number
  offset: number
  ordering: Ordering<T>[]
  search: string
}

interface DetailPanelProps {
  row: DataGridRowDef
}

export interface DataGridProps {
  columns: DataGridColumnDef[]
  rows: DataGridRowDef[]
  totalCount: number
  loading: boolean
  loadingMessage?: string | ReactNode
  error?: boolean
  errorMessage?: ReactNode
  emptyTable?: boolean
  emptyTableMessage?: ReactNode
  detailPanel?: ComponentType<DetailPanelProps>
  toolbar?: ComponentType
  paginationConfig?: {
    previousButtonText?: string
    previousAriaLabel?: string
    nextButtonText?: string
    nextAriaLabel?: string
    ofText?: string
    toText?: string
  }
}

const DataGrid = ({
  columns = [],
  rows = [],
  loading,
  loadingMessage = 'Loading...',
  error,
  errorMessage,
  emptyTable,
  emptyTableMessage,
  totalCount,
  detailPanel,
  toolbar: Toolbar,
  paginationConfig,
  ...rest
}: DataGridProps) => {
  const [{ offset, resultPerPage, hasDetailPanel }, { nextHandler, prevHandler }] = useDataGrid()

  const nextDisabled = useMemo(() => {
    const lastResultIndex = offset + resultPerPage
    return lastResultIndex > totalCount
  }, [offset, totalCount, resultPerPage])

  const previousDisabled = offset <= 0
  const showPagination = totalCount > resultPerPage
  const rowsWithKeys = useMemo(() => rows.map((row) => ({ ...row, key: uuid() })), [rows])

  return (
    <div className="overflow-auto" {...rest}>
      {Toolbar && <Toolbar />}

      <Table
        loading={loading}
        loadingMessage={loadingMessage}
        emptyTable={emptyTable}
        emptyTableMessage={emptyTableMessage}
        error={error}
        errorMessage={errorMessage}
      >
        <TableHead>
          <TableRow>
            {columns.map((column: DataGridColumnDef) => (
              <DataGridHeader key={column.field} column={column} />
            ))}

            {/* The next table header is needed to create new column for collapse buttons  */}
            {hasDetailPanel && <TableHeader className="lg:table-cell hidden" />}
          </TableRow>
        </TableHead>

        <TableBody>
          {rowsWithKeys.map((row: DataGridRowDef) => (
            <DataGridRow key={row.key} row={row} columns={columns} detailPanel={detailPanel} />
          ))}
        </TableBody>
      </Table>

      {showPagination && (
        <Pagination
          nextDisabled={nextDisabled}
          previousDisabled={previousDisabled}
          onClickNext={nextHandler}
          onClickPrevious={prevHandler}
          currentStartRange={offset + 1}
          currentEndRange={!nextDisabled ? offset + resultPerPage : totalCount}
          totalResults={totalCount}
          {...paginationConfig}
        />
      )}
    </div>
  )
}

const DataGridWithProvider = <T extends string>({
  initialState,
  enableSearchQuery,
  onChange,
  onRowClick,
  resultPerPage,
  detailPanel,
  ...rest
}: DataGridProps & DataGridProviderProps<T>) => (
  <DataGridProvider<T>
    initialState={initialState}
    onChange={onChange}
    onRowClick={onRowClick}
    resultPerPage={resultPerPage}
    enableSearchQuery={enableSearchQuery}
    detailPanel={detailPanel}
  >
    <DataGrid detailPanel={detailPanel} {...rest} />
  </DataGridProvider>
)

export { DataGridWithProvider as DataGrid }
