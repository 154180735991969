import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'

import { Button, Typography } from '@electro/shared-ui-components'

interface PaginationProps {
  totalResults: number
  currentStartRange: number
  currentEndRange: number
  onClickPrevious: () => void
  onClickNext: () => void
  nextDisabled: boolean
  previousDisabled: boolean
  previousButtonText?: string
  previousAriaLabel?: string
  nextButtonText?: string
  nextAriaLabel?: string
  ofText?: string
  toText?: string
}

export const Pagination = ({
  totalResults,
  currentStartRange,
  currentEndRange,
  onClickPrevious,
  onClickNext,
  nextDisabled,
  previousDisabled,
  previousButtonText = 'Previous',
  previousAriaLabel = 'Previous page',
  nextButtonText = 'Next',
  nextAriaLabel = 'Next page',
  ofText = 'of',
  toText = 'to',
}: PaginationProps) => (
  <nav
    className="flex flex-row justify-between items-center w-full border-t-2 pt-8 border-none"
    data-testid="pagination-nav"
  >
    <Button
      disabled={previousDisabled}
      onClick={onClickPrevious}
      variant="outline"
      size="xs"
      aria-label={previousAriaLabel}
      className="w-1/3"
    >
      <ChevronLeftIcon className="w-5 h-5 -ml-3 -mt-0.5" />
      <span>{previousButtonText}</span>
    </Button>
    <Typography variant="small" className="text-center">
      {currentStartRange} {toText} {currentEndRange} {ofText} <br className="md:hidden" />{' '}
      {totalResults}
    </Typography>
    <Button
      disabled={nextDisabled}
      onClick={onClickNext}
      variant="outline"
      size="xs"
      className="w-1/3"
      aria-label={nextAriaLabel}
    >
      <span>{nextButtonText}</span>
      <ChevronRightIcon className="w-5 h-5 -mr-3 -mt-0.5" />
    </Button>
  </nav>
)
